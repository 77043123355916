// import mediumZoom from 'medium-zoom'

// mediumZoom('[data-zoomable]', {
// 	margin: 16,
// 	background: "#fff5e9"
// })

import Zooming from 'zooming'

document.addEventListener('DOMContentLoaded', function () {
	function onOpen(img) {
		// When the target is fully opened...

		const picture = img.parentElement;

		// <source type="image/jpeg" media="all" srcset="http://localhost:1234/new-york-girl-2.8ecab7c0.jpeg?1642609165904">
		const source = document.createElement("source");
		source.setAttribute("type", "image/jpeg")
		source.setAttribute("media", "all")
		source.setAttribute("srcset", img.getAttribute("data-original"))
		source.classList.add("temp")

		// append as first child, thus giving top priority
		picture.prepend(source)
		console.log("prepended", source)
	}
	function onClose(img) {
		// When the target is fully closed...
		const picture = img.parentElement;
		picture.querySelectorAll("source.temp").forEach(source => source.remove())
		console.log("removing", picture.querySelectorAll("source.temp"))
	}
	const zooming = new Zooming({
		// customSize: "100%",
		bgColor: "#fff5e9",
		enableGrab: false,
		scaleBase: .8,
		// scrollThreshold: 0,
		onOpen,
		onClose,
	})

	zooming.listen('[data-zoomable]')
})

// import Zoom from "./zoom";


// // Css Selector
// Zoom("[data-zoomable]", {
//     originalizer: (src, image) => {
//     	console.log(src, image, image.getAttribute("data-orginal"))
//     	return image.getAttribute("data-original")
//     },
//     background: "auto",
// });
